$this: '.content-rsce-ticker';

#{$this} {
    color: #fff;
    font-size: 18px;
    line-height: 1.4;
    background: map-get($color, primary);

    p {
        margin: 10px 0 10px 10px;

        &::before {
            margin-right: 10px;
            content: '–';
        }
    }
}
