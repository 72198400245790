:root {
    --swiper-theme-color: #0062ad;
    --swiper-pagination-bullet-inactive-color: #f2f2f2;
    --swiper-pagination-bullet-inactive-opacity: 1;
}

.content-swiper {
    .swiper-thumbs {
        position: relative;

        --swiper-pagination-bottom: 35px;

        .content-rsce-imageheadline {
            margin: $vr * 0.5;
            cursor: pointer;
        }
    }
}
