.section-footer {
    padding: 20px $vr;
    color: #fff;
    line-height: 1.2;
    background: map-get($color, primary);

    a:hover {
        text-decoration: underline;
    }

    &__inside {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;

        > * {
            width: calc(50% - 20px);
        }
    }

    &__address {
    }

    &__contact {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 50%;

        a {
            display: flex;
            align-items: center;
            min-height: 40px;
            padding-left: 50px;
            font-weight: map-get($font-weight, bold);

            &.phone {
                background: url('../../images/phone.svg') 0 50%/40px no-repeat;
            }

            &.mail {
                background: url('../../images/mail.svg') 0 50%/40px no-repeat;
            }
        }
    }

    &__social {
        ul {
            display: flex;
            gap: 20px;
        }

        a {
            display: block;
            width: 40px;
            height: 40px;
            text-indent: -999em;

            &.instagram {
                background: url('../../images/social-instagram.svg') 0 0 / contain no-repeat;
            }

            &.facebook {
                background: url('../../images/social-facebook.svg') 0 0 / contain no-repeat;
            }

            &.linkedin {
                background: url('../../images/social-linkedin.svg') 0 0 / contain no-repeat;
            }
        }
    }
}

@include screen(medium, '.section-footer') {
    &__inside {
        flex-wrap: nowrap;

        > * {
            width: auto;
        }
    }

    &__address {
        margin-right: $vr;
    }

    &__contact {
        flex-grow: 1;
    }

    &__navigation {
        min-width: 100px;
        margin-right: $vr;
    }
}
