$body-font: 'myriad-pro-condensed,Arial,sans-serif';
$color: (
    text: #000,
    primary: #0062ad,
    background: #f2f2f2,
    navigation: #8c8c8c,
    link: #0062ad,
    table-even: #edf0fa,
    table-odd: #fcfcfb,
    border: #b2b2b2,
);
$vr: 35px;
$corner: 15px;
$container: 1180px;

@mixin screen($size, $block: null) {
    $sizes: (
        mobile-only: 'max-width: 767px',
        medium: 'min-width: 768px',
        //tablet-only: "min-width: 768px) and (max-width: 1219px",
        large: 'min-width: 1220px',
    );

    @if map-has_key($sizes, $size) {
        $size: map-get($sizes, $size);
    } @else if type_of($size) == 'number' {
        $size: 'min-width: #{$size}px';
    }

    @media screen and (#{unquote($size)}) {
        @if $block {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

@mixin clearfix($prop: after) {
    &:#{$prop} {
        display: table;
        clear: both;
        content: '';
    }
}
