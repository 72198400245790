$this: '.content-rsce-product';

#{$this} {
    padding: calc($vr / 2) $vr;
    border: 1px solid map-get($color, primary);
    border-radius: $corner;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        font-size: 18px;
    }

    &__image {
        margin-top: calc($vr / 2);
    }
}
