main {
    padding: $vr * 0.5;
    font-weight: map-get($font-weight, light);
    font-size: 24px;
    line-height: 1.2;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: map-get($color, primary);
        font-weight: map-get($font-weight, bold);
    }

    a {
        color: map-get($color, link);
        text-decoration: underline;
        transition: opacity 0.1s ease;

        &:hover {
            opacity: 0.75;
        }
    }
}

@include screen(medium, 'main') {
    max-width: $container;
    margin: 0 auto;
    padding: $vr * 0.5 40px;
}
