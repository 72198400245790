$this: '.content-rsce-text';

#{$this} {
    .mod_article > & {
        margin: $vr * 0.5;
    }

    &__image {
        margin-bottom: 5px;
        text-align: center;
    }

    &__headline {
        margin: 0;
        font-size: 30px;
        text-align: center;

        &--large {
            font-size: 89px;
        }

        sup {
            font-size: 45px;
            vertical-align: super;
        }
    }

    &__text {
        p:last-child {
            margin-bottom: 0;
        }
    }

    &--background {
        padding: 20px;
        background: map-get($color, background);
        border-radius: $corner;
    }

    &--center {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &--text-small {
        font-size: 18px;
    }
}
