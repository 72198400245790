.content-table {
    margin: $vr * 0.5;
    font-size: 18px;

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th,
    td {
        padding: 1px 5px;
        border: 1px solid #fff;
    }

    th {
        color: map-get($color, primary);
        font-weight: map-get($font-weight, bold);
        font-style: italic;
        background: map-get($color, table-even);
    }

    tbody {
        tr:nth-child(even) td {
            background: map-get($color, table-even);
        }

        tr:nth-child(odd) td {
            background: map-get($color, table-odd);
        }
    }

    &.prices {
        th:last-child,
        td:last-child {
            text-align: right;
        }
    }
}
