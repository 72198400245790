.content-grid {
    display: grid;

    &__element {
        padding: $vr * 0.5;

        > * {
            width: 100%;
            height: 100%;
        }
    }
}
