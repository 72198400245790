.section-header {
    &__inside {
        padding: 15px;
        text-align: center;
    }

    &__logo {
        position: relative;
        z-index: 100;
        display: inline-block;
        width: 140px;
        height: 100px;
        background: url('../../images/logo.svg') center center/contain no-repeat;
    }

    &__toggle {
        position: absolute;
        top: 30px;
        right: 40px;
        z-index: 100;
        width: 3em;
        height: 3em;
        font-size: 12px;
        background: none;
        border: none;
        cursor: pointer;
        transition: 0.2s all;
        -webkit-tap-highlight-color: transparent;

        &::after {
            position: absolute;
            top: -25%;
            left: -25%;
            display: block;
            width: 150%;
            height: 150%;
            content: '';
        }

        > span {
            top: 50%;
            margin-top: -0.125em;

            &,
            &::after,
            &::before {
                position: absolute;
                display: block;
                width: 100%;
                height: 0.25em;
                background-color: map-get($color, primary);
                border-radius: 0.25em;
                transform: rotate(0);
                transition: all 0.2s;
                content: '';
                pointer-events: none;
            }

            &::after {
                top: -1em;
                left: 0;
            }

            &::before {
                top: 1em;
                left: 1em;
            }

            &::after,
            &::before {
                width: 2em;
            }
        }

        body.has-overlay & {
            > span {
                & {
                    background-color: transparent;
                    transform: translateX(-2em);
                }

                &::before,
                &::after {
                    top: 0;
                    left: 0.5em;
                }

                &::before {
                    transform: translateX(2em) rotate(135deg);
                }

                &::after {
                    transform: translateX(2em) rotate(-135deg);
                }
            }
        }
    }

    &__overlay {
        position: fixed;
        inset: 0;
        z-index: 99;
        overflow-y: auto;
        background: #fff;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;

        body.has-overlay & {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__navigation {
        height: 100%;
        margin-top: 200px;
        line-height: 1.2;

        a,
        strong {
            color: map-get($color, navigation);
            font-weight: map-get($font-weight, bold);
            transition: color 0.1s ease;

            &:hover,
            &.trail,
            &.active {
                color: map-get($color, primary);
            }
        }
    }

    &__slider {
        position: relative;

        &-image {
            .image_container {
                width: 100vw;
                height: 230px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }

        &-text {
            position: absolute;
            top: 0;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100vw;
            max-width: 1100px;
            height: 230px;
            margin: 0 auto;
            padding: 0 calc($vr * 0.5);
            pointer-events: none;

            p {
                margin: 30px;
                padding: $vr;
                color: #fff;
                font-weight: map-get($font-weight, bold);
                line-height: 1.2;
                text-align: center;
                background: map-get($color, primary);
                border-radius: $corner;
                pointer-events: auto;
            }
        }
    }
}

body.has-overlay {
    overflow: hidden;
}

@include screen(medium, '.section-header') {
    text-align: left;

    &__inside {
        display: flex;
        gap: 40px;
        justify-content: space-between;
        max-width: $container;
        margin: 0 auto;
        padding: 15px $vr;
    }

    &__toggle {
        display: none;
    }

    &__overlay {
        position: static;
        padding-top: 0;
        overflow: hidden;
        opacity: 1;
        pointer-events: all;
    }

    &__navigation {
        margin-top: 40px;

        ul {
            display: flex;
            gap: 25px;
        }
    }

    &__slider {
        &-image {
            .image_container {
                height: 430px;
            }
        }

        &-text {
            left: 50%;
            height: 430px;
            transform: translateX(-50%);

            p {
                width: calc(50% - $corner);
                font-size: 40px;
            }
        }
    }
}

@include screen(large, '.section-header') {
    &__logo {
        height: 150px;
    }

    &__navigation {
        margin-top: 80px;
        margin-right: 100px;
    }

    &__slider {
        &-image {
            .image_container {
                height: 430px;
            }
        }

        &-text {
            left: 50%;
            height: 430px;
            transform: translateX(-50%);

            p {
                width: calc(50% - $corner);
                margin: 0;
                font-size: 40px;
            }
        }
    }
}
