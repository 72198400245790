$this: '.content-image';

#{$this} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    border: 1px solid map-get($color, border);
    border-radius: $corner;
}
