.content-map {
    min-height: 400px;
    border-radius: $corner;

    .sortag-icon {
        width: 70px;
        height: 70px;
        background: rgba(255,255,255,0.5) url('../../images/logo.svg') center center/45px no-repeat;
        border-radius: 50%;
    }
}
