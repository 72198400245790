$this: '.content-rsce-imageheadline';

#{$this} {
    position: relative;

    .mod_article > & {
        margin: $vr * 0.5;
    }

    &__headline {
        position: absolute;
        right: 0;
        left: 0;
        margin: 0;
        padding: 0.5em;
        color: #fff;
        font-size: 40px;
        text-align: center;
        transition: background-color 0.2s ease-in-out;

        &--top {
            top: 0;
            background: linear-gradient(map-get($color, primary), transparent);
            border-radius: $corner $corner 0 0;
        }

        &--bottom {
            bottom: 0;
            background: linear-gradient(transparent, map-get($color, primary));
            border-radius: 0 0 $corner $corner;
        }

        .swiper-slide-thumb-active & {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            background: rgba(map-get($color, primary), 0.6);
            border-radius: $corner;
            inset: 0;

            &--bottom {
                align-items: flex-end;
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            border-radius: $corner;
        }
    }
}
